import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { VisibilityBasic } from "../../examples/utilities/Visibility";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CodeBlock code={VisibilityBasic} mdxType="CodeBlock">
  <p>
    Set the <code>visibility</code> of elements with our visibility utilities.
    These utility classes do not modify the <code>display</code> value at all
    and do not affect layout – <code>.invisible</code> elements still take up
    space in the page. Content will be hidden both visually and for assistive
    technology/screen reader users.
  </p>
  <p>
    Apply <code>.visible</code> or <code>.invisible</code> as needed.
  </p>
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      